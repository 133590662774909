body {
  margin: 0;
  touch-action: pan-x pan-y;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

.item-container {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 10px;
  background-color: #f3f3f3;
}

.toggleButtonGroup{
  display: flex;
  column-gap: 10px;
  flex-direction: row;
}

.iconButton {
  width: 30px!important;
  min-width: 30px!important;
  padding: 0px!important;
  vertical-align: middle;
}

.titleCell {
  text-align: enter
}

.defaultDivGap {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.footer-button-group {
  display: flex;
  column-gap: 10px;
  justify-content: center;
  margin-bottom: 15px
}

.formConatiner {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  max-width: 600px;
  overflow-x: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.inputContainer {
  margin-bottom: 10px;
}

.gridInputContainer {
  overflow-x: auto;
  margin-bottom: 10px;
}

.datetimeInput {
  display: grid;
  column-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  max-width: 600px;
  margin-bottom: 10px
}

.headerRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fui-Textarea {
  min-height: 150px;
}

.fullscreen {
  height: 100vh
}
.searchBar {
  max-width: 595px!important;
}
.gridCellInput {
  width: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} 

.toggleButtonSelected {
  background-color: #ff9f0a!important;
  color: black!important;
}

.ac-textBlock p {
  color: black;
}

.ac-adaptiveCard {
  background-color: #f3f3f3;
  border-radius: 10px;
}

.ac-adaptiveCard:active {
  background-color: #e3e3e3;
}

.ac-adaptiveCard:hover {
  cursor: pointer;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(320px, 1fr));
  column-gap: 10px;
  row-gap: 10px;
  margin: 10px;
}


@media screen and (max-width: 600px) {
  .formConatiner {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    max-width: 500px;
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 50px;
    overflow-x: scroll;
  }

  .datetimeInput {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin-bottom: 10px;
  }

  .toggleButtonGroup{
    display: flex;
    row-gap: 10px;
    flex-direction: column;
    margin-top: 10px;;
  }
}


@media (prefers-color-scheme: dark) {
  .item-container {
    background-color: black;
  }
  .ac-textBlock p {
    color: white;
  }

  .ac-adaptiveCard {
    background-color: black;
  }

  .ac-adaptiveCard:active {
    background-color: #202020;
  }

  .ac-textBlock p {
    color: white;
  }
}